<template>
  <div class="overflow-auto data-table">
    <half-circle-spinner
      v-show="isLoading"
      :size="50"
      color="#008ecf"
      class="pre-loader"
    />
    <vuetable
      v-if="fields.length"
      v-show="!isLoading"
      class="text-center black table table-striped"
      ref="vuetable"
      :api-mode="false"
      :fields="fields"
      :per-page="pagination.perPage"
      :data-manager="dataManager"
      :no-data-template="$t('shared.table.no-data-available')"
      pagination-path="pagination"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="actions" slot-scope="props">
        <slot name="actions" v-bind="props"></slot>
      </template>
    </vuetable>
    <div class="d-flex justify-content-end" style="padding-top: 10px">
      <vuetable-pagination
        ref="pagination"
        :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import _ from 'lodash'
import dataTableStyles from '../vuestic-theme/vuestic-components/vuestic-datatable/data/data-table-styles'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  name: 'app',

  components: {
    Vuetable,
    HalfCircleSpinner,
    VuetablePagination
  },
  props: {
    fields: {
      type: Array
    },
    data: {
      type: Array
    },
    pagination: {
      type: Object
    },
    isLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      css: dataTableStyles
    }
  },

  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh()
    },
    fields(newVal, oldVal) {
      this.$refs.vuetable.normalizeFields()
    }
  },

  mounted() {
    console.log('??', this.data)
  },

  methods: {
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$emit('pageChange', page)
      this.$refs.vuetable.changePage(page)
    },
    dataManager(sortOrder, pagination) {
      // if (this.data.length < 1) return

      let local = this.data

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log('orderBy:', sortOrder[0].sortField, sortOrder[0].direction)
        local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction)
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.pagination.perPage
      )
      let from = pagination.from - 1
      let to = from + this.pagination.perPage

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      }
    },
    onActionClicked(action, data) {
      console.log('slot actions: on-click', data.name)
    },
    refresh() {
      this.$refs.vuetable.refresh()
    },
    normalizeFields() {
      this.$refs.vuetable.normalizeFields()
    }
  }
}
</script>

<!--  -->
<style lang="scss">
.data-table {
  min-height: 24rem;
  padding: 2px;
  .datatable-controls {
    background-color: $white;
    border-radius: 9px;
    padding: 15px 20px;
    margin-bottom: 30px;
    box-shadow: $widget-box-shadow;
    flex-wrap: wrap;
    .additionalTableControl {
      margin-left: auto;
    }
  }
  .table {
    box-shadow: $widget-box-shadow;
    border-radius: 9px;
    border-collapse: separate;
    border-spacing: 0;
    thead {
      background-color: $blue;
      tr {
        color: $white;
        border-color: $blue;
        th {
          text-transform: capitalize;
          &:first-child {
            border-top-left-radius: 9px;
          }
          &:last-child {
            border-top-right-radius: 9px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid lighten($gray, 20%);
        }
        &:last-child {
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          td {
            &:first-child {
              border-bottom-left-radius: 9px;
            }
            &:last-child {
              border-bottom-right-radius: 9px;
            }
            &.vuetable-empty-result {
              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;
            }
          }
        }
      }
    }
  }

  .form-group {
    margin-bottom: 1rem;
  }

  th,
  td {
    font-weight: 400;
  }
  @media (max-width: 1440px) {
    th,
    td {
      font-size: 12px;
    }
  }

  @media (max-width: 1258px) {
    .pagination-link-btn:first-child,
    .pagination-link-btn:last-child {
      display: none;
    }

    .pagination-link-btn:nth-child(2) {
      border-top-left-radius: $btn-border-radius !important;
      border-bottom-left-radius: $btn-border-radius !important;
    }

    .pagination-link-btn:nth-last-child(2) {
      border-top-right-radius: $btn-border-radius !important;
      border-bottom-right-radius: $btn-border-radius !important;
    }
  }

  @media (max-width: 991px) {
    .datatable-controls {
      div:not(.btn-with-icon-content) {
        margin-top: 10px;
      }
    }
  }
  @media (max-width: 576px) {
    .hide-not-focused-btn:not(.focus) {
      display: none;
    }
  }

  .data-table-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 50%;
  }
  .btn-plain {
    color: $main-light;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.2s ease;
  }
  .btn-plain:hover {
    cursor: pointer;
    font-weight: bold;
    color: darken($main-light, 10%);
  }
  .datatable-btns {
    width: 25px;
    height: 25px;
    margin: 0 5px;
    transform: scale(1);
    transition: transform 0.1s ease-in;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
  .datatable-delete_btn {
    font-size: 25px;
    margin: 0 5px;
    vertical-align: middle;
    transform: scale(1);
    transition: all 0.1s ease-in;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      color: $brand-danger;
    }
  }
  .datatable-play_btn {
    font-size: 15px;
    margin: 0 5px;
    vertical-align: middle;
    transform: scale(1);
    transition: all 0.1s ease-in;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      color: #008ecf;
    }
  }
}

.data-loading {
  opacity: 0.5;
  pointer-events: none;
}
tr.expanded,
tr.collapsed {
  &:hover {
    cursor: pointer;
  }
  td:first-child {
    position: relative;
  }
  td:first-child:after {
    content: '\25BC';
    position: absolute;
    right: 10px;
    color: $blue !important;
    transform: rotate(-180deg);
    transition: transform 0.3s ease-in-out;
  }
}
tr.collapsed {
  td:first-child:after {
    transform: rotate(0deg);
  }
}
tr.disabled {
  color: $gray;
  cursor: default;
}
</style>

<style lang="scss">
.rtl {
  .data-table {
    .pagination-link-btn:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 1.875rem;
      border-bottom-right-radius: 1.875rem;
      .fa-angle-double-left::before {
        content: '<<';
      }
    }
    .pagination-link-btn:last-child {
      border-top-left-radius: 1.875rem;
      border-bottom-left-radius: 1.875rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .fa-angle-double-right::before {
        content: '>>';
      }
    }
    .pagination-link-btn:nth-child(2) {
      .fa-angle-left::before {
        content: '<';
      }
    }

    .pagination-link-btn:nth-last-child(2) {
      .fa-angle-right::before {
        content: '>';
      }
    }
  }
  .data-table {
    .table {
      thead {
        tr {
          th {
            &:first-child {
              border-top-left-radius: 0px;
            }
            &:last-child {
              border-top-right-radius: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
